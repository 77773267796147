.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: rgba(0, 191, 255, 0.025);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(245, 245, 245, 0.018);
  position: fixed;
  z-index: 2;
  font-family: var(--font-base);
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-logo);
  font-size: 2.2rem;

  a {
    color: var(--gray-color);
    text-decoration: none;
    transition: 0.2s ease;

    &:hover {
      color: var(--secondary-color);
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;

  li {
    margin: 0 1rem;
    flex-direction: column;

    div {
      width: 5px;
      height: 5px;
      background: transparent;
      border-radius: 50%;
      transition: 0.2s ease-in-out;
      margin: auto;
      margin-bottom: 5px;
    }

    a {
      color: var(--gray-color);
      text-decoration: none;
      flex-direction: column;
      text-transform: uppercase;
      font-weight: 500;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: var(--secondary-color);
      }
    }
    &:hover {
      div {
        background-color: var(--secondary-color);
      }
    }
  }

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);

  svg {
    width: 70%;
    height: 70%;
    color: var(--white-color);
    cursor: pointer;
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    padding: 1rem;
    width: 80%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    background: url("../../assets/bgWhite.png ");
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.015);

    svg {
      width: 35px;
      height: 35px;
      color: var(--secondary-color);
      margin: 0.5rem 1rem;
    }

    ul {
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }

    li {
      list-style: none;
      margin: 1rem;

      a {
        text-decoration: none;
        color: var(--gray-color);
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
        &:hover {
          color: var(--secondary-color);
        }
      }
    }
    @media screen and (min-width: 700px) {
      display: none;
    }
  }
  @media screen and (min-width: 700px) {
    display: none;
  }
}
