.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  margin-top: 3rem;
  gap: 2rem;
  padding: 1rem;
  @media screen and (max-width: 1540px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1140px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 770px) {
    grid-template-columns: 1fr;

    .app__profile-item {
      width: 100%;
      padding: 1rem;

      img {
        height: 400px;
      }
    }
  }
}

.app__profile-item {
  width: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }
}
